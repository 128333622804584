/**
 * Production env variable values (prd for client package and production for all other packages)
 */
const PROD_ENVIRONMENTS = ['prd', 'production']

/**
 * Checks whether the passed environment variable value matches the production value.
 */
export const getIsProductionAppEnv = (appEnv: string) => {
  return PROD_ENVIRONMENTS.includes(appEnv)
}
